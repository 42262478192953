import { Button } from '@/app/src/shared/ui/Button'
import useLocalStorageState from 'use-local-storage-state'

import './AllowButton.scss'
import { analyticsApi } from '@/app/src/entities/analytics'

const AllowButton = () => {
  const [value, setValue] = useLocalStorageState('cookie_consent_v5')

  const handleClick = () => {
    const currentValue = value ?? {}
    const newValue = {
      ...currentValue,
      analytics_storage: 'granted',
      ad_storage: 'granted',
      personalization_storage: 'granted',
      functionality_storage: 'granted',
      security_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    }

    setValue(newValue)
    analyticsApi.sendCookieConcent(newValue)
  }

  return (
    <Button onClick={handleClick} variant="main" hoverVariant="purple-light" size="s">
      Ok fine
    </Button>
  )
}

export default AllowButton
