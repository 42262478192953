'use client'

import cn from 'classnames'
import './CookieForm.scss'
import { AllowButton } from '@/app/src/features/allow-cookies/ui/AllowButton'
import ImageCookie from '../../images/cookie.png'
import useLocalStorageState from 'use-local-storage-state'
import { useEffect, useState } from 'react'
import { isLightHouse } from '@/app/src/shared/lib/detectDevice'
import { NextImage } from '@/app/src/shared/ui/NextImage'

export type ICookieForm = {
  className?: string
}

const CookieForm = ({ className }: ICookieForm) => {
  const [value] = useLocalStorageState('cookie_consent_v5', {
    defaultValue: null,
  })

  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    if (isLightHouse) return

    setVisible(value === null)
  }, [value])

  return (
    <section
      className={cn('cookie-form', {
        [`${className}`]: !!className,
        'cookie-form--is-visible': isVisible,
      })}
    >
      <div className="cookie-form__left">
        <p className="cookie-form__text">
          Yes, we use{' '}
          <a
            target="_blank"
            href="https://docs.google.com/document/d/1FDUdOrdaOsO7fcQAFK2wWFoXP3T8XRD-AK8kc90avyA/edit"
          >
            cookies
          </a>{' '}
          on our website.
        </p>

        <div className="cookie-form__button">
          <AllowButton />
        </div>
      </div>

      <div className="cookie-form__right">
        <NextImage
          src={ImageCookie}
          width={90}
          height={90}
          alt="Cookie Image"
          artDirection={{ sm: { width: 60, height: 60 } }}
        />
      </div>
    </section>
  )
}

export default CookieForm
