'use client'

import cn from 'classnames'
import './Navigation.scss'
import Link from 'next/link'
import { linkBlog, linkPortfolio, menuPart } from '@/app/src/widgets/Layout/Footer/config'
import { Icon } from '@/app/src/shared/ui/Icon'
import { useCollapse } from 'react-collapsed'
import { usePathname } from 'next/navigation'

export type IProps = {
  className?: string
}

const Navigation = () => {
  const pathname = usePathname()

  return (
    <nav className={cn('navigation__top-right-grid')}>
      <ol className={cn('navigation__top-right-cell')}>
        <li className={cn('navigation__nav-item')}>
          <Link
            href={linkBlog?.href ?? '/'}
            className={cn('navigation__title-link', 'navigation__title-link--white', {
              'navigation__title-link--active': pathname?.startsWith(
                (linkBlog?.href ?? '') as string,
              ),
            })}
          >
            {linkBlog?.titleAlternative}
          </Link>
        </li>

        <li className={cn('navigation__nav-item')}>
          <Link
            href={linkPortfolio?.href ?? '/'}
            className={cn('navigation__title-link', 'navigation__title-link--white', {
              'navigation__title-link--active': pathname?.startsWith(
                (linkPortfolio?.href ?? '') as string,
              ),
            })}
          >
            {linkPortfolio?.titleAlternative}
          </Link>
        </li>
      </ol>

      {menuPart.map((item, idx) => (
        <ol
          key={item?.id}
          className={cn('navigation__top-right-cell', {
            'navigation__top-right-cell--large': idx === menuPart.length - 1,
          })}
        >
          <Item itemMenu={item} />
        </ol>
      ))}
    </nav>
  )
}

const Item = ({ itemMenu }: { itemMenu: typeof linkBlog }) => {
  const pathname = usePathname()

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  const hasSubmenu = !!itemMenu?.subnav.length

  return (
    <li
      className={cn('navigation__nav-item', { 'navigation__nav-item--submenu-opened': isExpanded })}
    >
      <Link
        href={itemMenu?.href ?? '/'}
        className={cn('navigation__title-link', 'navigation__title-link--white', {
          'navigation__title-link--active': pathname?.startsWith((itemMenu?.href ?? '') as string),
        })}
      >
        {itemMenu?.titleAlternative}
      </Link>

      {hasSubmenu && (
        <>
          <div className={cn('navigation__open-menu-button-wrap')}>
            <button {...getToggleProps()} className={cn('navigation__open-menu-button')}>
              <Icon width="22" height="22" name="arrows/chevron-down" />
            </button>
          </div>

          <div className={cn('navigation__subnav-wrap')} {...getCollapseProps()}>
            <ol className={cn('navigation__subnav')}>
              {itemMenu.subnav.map(({ title, href, id }) => (
                <li key={id} className={cn('navigation__nav-subitem')}>
                  <Link
                    href={href}
                    className={cn('navigation__nav-link', {
                      'navigation__nav-link--active': pathname?.startsWith((href ?? '') as string),
                    })}
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ol>
          </div>
        </>
      )}
    </li>
  )
}

export default Navigation
