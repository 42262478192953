/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import Script from 'next/script'
import useLocalStorageState from 'use-local-storage-state'
import { useGtag, useYandexMetrica } from '@/app/src/entities/analytics/hooks'
import { analyticsApi } from '@/app/src/entities/analytics/api'

const InitAnalytics = () => {
  useYandexMetrica()

  const { isInitGtag, gtagId } = useGtag()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [cookieAnswer] = useLocalStorageState('cookie_consent_v5')

  useEffect(() => {
    const url = `${pathname}?${searchParams ? searchParams.toString() : ''}`

    if (isInitGtag) {
      analyticsApi.pageview(url)
    }
  }, [pathname, searchParams, isInitGtag, gtagId])

  useEffect(() => {
    if (isInitGtag) {
      if (cookieAnswer !== null) {
        analyticsApi.sendCookieConcentDefault(cookieAnswer)
      } else {
        analyticsApi.sendCookieConcentDefault()
      }
    }
  }, [isInitGtag])

  return (
    <>
      <Script
        id="_next-ga-init"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
          `,
        }}
      />
    </>
  )
}

export default InitAnalytics
